<template>
    <Main />
</template>

<script>
import Main from './components/layouts/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  },
  watch: {
    $route (to, from) {
      /* eslint-disable */

      // (function(c,l,a,r,i,t,y){
      //   c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      //     t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      //     y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      // })(window, document, "clarity", "script", "bfvo8zb800");

      window._qevents = window._qevents || [];

      (function() {
        var elem = document.createElement('script');
        elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
        elem.async = true;
        elem.type = "text/javascript";
        var scpt = document.getElementsByTagName('script')[0];
        scpt.parentNode.insertBefore(elem, scpt);
      })();

      window._qevents.push({
        qacct:"p-ThTHtWGTKvkAx",
        uid:"__INSERT_EMAIL_HERE__"
      });
    }
  }
}
</script>
