<template>
  <div class="global-search-autocomplete serchnewdesign">
    <input
      id="globalSearchInput"
      type="text"
      @input="onChange"
      v-model="search"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      class="header-auto-search"
      ref="globalSearchInput"
      placeholder="Search for performers, events and venues"
      @focus="blurBackground"
      @blur="removeBlurFromBody"
    />
    <button class="search-cancel-btn mobile-search-cancel" v-bind:class="{ none: isGlobalSearchNotInFocus }">
      <img src="images/close-icon.svg">
    </button>
    <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
      <li class="loading autocomplete-loader" v-if="isLoading">
        <i class="fa fa-circle-o-notch fa-spin"></i>
      </li>
      <div class="global-search-content-wrap" v-if="results.performers">
        <p class="no-records-found" v-if="results.performers.length===0 && results.events.length===0 && results.venues.length===0">No Results found !</p>
        <h3 class="search-title" v-if="results.performers.length>0">Performers</h3>
        <a :href="`/events/${result.slug}/${result.performerId}`"
          v-for="(result, i) in results.performers"
          :key="i"
          class="performers-listing"
          :class="{ 'is-active': i === arrowCounter }"
        >
         <span class="full-date">{{ result.name }}</span>
        </a>
        <h3 class="search-title search-event-title"  v-if="results.events.length>0">Events</h3>
        <a
          href="javascript:void(0)"
          v-for="(result, i) in results.events"
          :key="i"
          @click="setResult(result)"
          class="autocomplete-result search-result-item"
          :class="{ 'is-active': i === arrowCounter }"
          v-on:click="redirectToEventDetailPage" :data-href="'/event/' + result.eventId + '/' + result.slug" :data-event-name="result.eventName" :data-venue-name="result.venue.name" :data-venue-city="result.venue.city" :data-venue-state="result.venue.state" :data-venue-timezone="result.venue.timezone" :data-venue-countrycode="result.venue.countryCode" :data-event-date="result.eventDateString"
        >
          <div class="search-result-item-info">
            <div class="event-list search_event_list">
              <div class="event-date">
                <div class="date-wrapper">
                  <span class="full-date dateonly">{{ formatEventDateOnly(result.eventDateString) }}</span>
                  <span class="full-date">{{ formatEventDateMonthYear(result.eventDateString) }}</span>
                  <!-- <span class="full-date">{{ formatEventDate(result.eventDateString) }}</span> -->
                </div>
              </div>
              <div class="event-details">
                <h3 class="venue-title">{{ result.eventName }}</h3>
                <div class="full_add">
                  <span class="venue-address"><img class="venue-icon" src="/images/venue-map-icon.svg"><span>{{ result.venue.name }}, {{ result.venue.city }}</span></span>
                  <span class="venue-address"><img class="venue-icon" src="/images/venue-city-icon.svg"><span>{{ result.venue.state }}, {{ result.venue.timezone }}, {{ result.venue.countryCode }}</span></span
                ></div>
                <div class="date-time">
                  <span><img class="venue-day-icon" src="/images/venue-day-icon.svg"><label>{{ getEventDay(result.eventDateString) }}</label></span>
                  <span><img class="venue-time-icon" src="/images/venue-time-icon.svg"><label>{{ formatEventTime(result.eventDateString) }}</label></span>
                </div>
              </div>
            </div>
          </div>
        </a>
        <h3 class="search-title search-event-title" v-if="results.venues.length>0">Venues</h3>
        <a :href="`/events/venue/${result.slug}/${result.venueId}`"
          v-for="(result, i) in results.venues"
          :key="i"
          class="performers-listing"
          :class="{ 'is-active': i === arrowCounter }"
        >
         <span class="full-date">{{ result.name }}</span>
        </a>
        <div class="show-more-wrap" v-if="results.events.length>0">
          <button v-on:click="showAllResults" type="button" class="show-more-btn">Show all results</button>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import moment from 'moment'
// import $ from 'jquery'
import Cookies from 'js-cookie'

export default {
  name: "GlobalSearchAutocomplete",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      results: [],
      search: this.$route.query.q,
      isLoading: false,
      arrowCounter: -1,
      GlobalSearchPlaceholder: 'Search for performers and events',
      isGlobalSearchNotInFocus: true,
      debounce: null
    };
  },
  watch: {
    items: function(value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
        this.isLoading = false;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    blurBackground: function (e) {
      this.isGlobalSearchNotInFocus = false
      document.body.classList.add("global-search-blur");
      document.getElementsByClassName('hero-1 banner-home')[0].classList.add('global-search-blur-banner')
      document.getElementById("globalSearchInput").setAttribute("placeholder", "");
      // $('body').addClass('global-search-blur')
      // $('#globalSearchInput').attr('placeholder', '')
    },
    removeBlurFromBody: function (e) {
      this.isGlobalSearchNotInFocus = true
      document.body.classList.remove("global-search-blur");
      document.getElementsByClassName('hero-1 banner-home')[0].classList.remove('global-search-blur-banner')
      document.getElementById("globalSearchInput").setAttribute("placeholder", this.GlobalSearchPlaceholder);
      // $('body').removeClass('global-search-blur')
      // $('#globalSearchInput').attr('placeholder', this.GlobalSearchPlaceholder)
    },
    showAllResults: function (e) {
      //document.body.classList.remove("global-search-blur");
      document.getElementsByClassName("mobile-search-icon-j")[0].classList.remove('mobile-search');
      var autocompleteResults = document.getElementsByClassName("autocomplete-results")[0];
      autocompleteResults.style.display = "none";
      // $('.mobile-search-icon-j').removeClass('mobile-search')
      // $('body').removeClass('global-search-blur')
      // $('.autocomplete-results').hide()
      this.$router.push({
        path: '/events',
        query: { q: this.$refs.globalSearchInput.value }
      })
    },
    redirectToEventDetailPage: function (e) {
      window.location = window.location.origin + e.currentTarget.getAttribute('data-href')
    },
    formatEventDate: function (date) {
      return moment(date).format('MMMM Do, YYYY')
    },
    formatEventDateOnly: function (date) {
      return moment(date).format('Do')
    },
    formatEventDateMonthYear: function (date) {
      return moment(date).format('MMM, YYYY')
    },
    formatEventTime: function (date) {
      return moment(date).format('hh:mm a')
    },
    getEventDay: function (date) {
      return moment(date).format('dddd')
    },
    setResult(result) {
      this.search = result.name || result.eventName;
      this.isOpen = false;
    },
    filterResults() {
      this.isLoading = true;
      this.results = [];
      axios.get(process.env.VUE_APP_API_URL + 'globalSearch', {
        params: {
          q: this.$refs.globalSearchInput.value,
          venueRegion: Cookies.get('userLocation')
        }
      }).then(response => {
        this.isLoading = false;
        this.results = response.data.data.results
      })
    },
    onChange() {
      this.$emit("input", this.search);
      if (! this.isAsync) {
        clearTimeout(this.debounce)
          this.debounce = setTimeout(() => {
          this.filterResults();
        }, 300)
        this.isOpen = true;
      }
    },
    handleClickOutside(event) {
      this.search = ''
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      window.location.href = process.env.BASE_URL + 'events?q=' + this.$refs.globalSearchInput.value
      /* this.search = this.results[this.arrowCounter];
      this.isOpen = false;
      this.arrowCounter = -1; */
      this.showAllResults()
    }
  }
};
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
.none {
  display: none;
}
</style>