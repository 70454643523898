import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
// import Home from '../views/home/Home.vue'
import Cookies from 'js-cookie'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home" */'../views/home/Home.vue'),
    meta: { headerClass: 'site-header common-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/events/:performerName?/:performerId?',
    name: 'Events',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "events" */'../views/events/Events.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/events/type/:categorieName?',
    name: 'Events Category',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "events" */'../views/events/Events.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/events/venue/:venueName?/:venueId?',
    name: 'Events Venue',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "events" */'../views/events/Events.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/events/region/:regionName?/:regionId?',
    name: 'Events Region',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "events" */'../views/events/Events.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/categories',
    name: 'All Categories',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "categories" */'../views/categories/Categories.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/category/:categorieName',
    name: 'Categories For Event Type',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "categories" */'../views/categories/Categories.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/event/:id/:eventName',
    name: 'Event Detail',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "eventDetails" */'../views/events/EventDetail.vue'),
    meta: { headerClass: 'site-header common-header inner-header event-details-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "login" */'../views/auth/Login.vue'),
  //   meta: { headerClass: 'site-header common-header inner-header event-details-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header' }
  // },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "contactUs" */'../views/contact-us/ContactUs.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "AboutUs" */'../views/about-us/aboutUs.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/terms-of-use',
    name: 'Terms Of Use',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "termsOfUse" */'../views/terms-of-use/TermsOfUse.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "privacyPolicy" */'../views/privacy-policy/PrivacyPolicy.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/payment/status/:eventid/:eventName',
    name: 'Payment Status',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "paymentStatus" */'../views/payment/Status.vue'),
    meta: { headerClass: 'site-header common-header inner-header event-details-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/performers/:categoryName?/:categoryId?',
    name: 'Performers for Category',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "performers" */'../views/performers/Performers.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/offerthankyou',
    name: 'Offer Thank You',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "404" */'../views/offer-thankyou/offer-thankyou.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "404" */'../views/404/404.vue'),
    meta: { headerClass: 'site-header common-header inner-header', topHeaderClass: 'top-header top-header-bg', mainHeaderClass: 'main-header', requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

trackRouter(router)

/* eslint-disable */
// router.beforeEach((to, from, next) => {
  // if (!Cookies.get('userLocation')){
  //   setTimeout(function () { next() }, 1000);  
  // }else{
  //   next()
  // }
  // next()
  /* if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('isLoggedIn') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()  
    }
  } else {
    next()
  }
  if (to.matched.some(record => record.meta.hideForAuth)) {
    if (localStorage.getItem('isLoggedIn')) {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
      next();
  } */
// })
/* eslint-enable */
export default router
