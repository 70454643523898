<style scoped>
.top-footer{
  background:#1f1f1f;
  padding:20px 0;
  /* border-top:8px solid #4e4e4e;
  border-bottom:1px solid #111 */
}
.top-footer .container {
    width: 100%;
    max-width: 100%;
    padding: 0 40px;
}
.footer-main-wrap{
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  flex-wrap: wrap
}
.footer-logo-sec{
  width:100%;
  text-align: center;
  margin-bottom: 20px
}
.top-footer .footer-logo-sec a img{
  max-height: 50px
}
.footer-contact-sec{
  width:32%
}
.footer-popular-events{
  width:32%;
  display:flex;
  flex-direction:column;
  align-items:flex-start
}
.footer-support{
  width:34%
}
.top-footer a{
  display:inline-block;
}
.top-footer .footer-logo-sec a img{
  object-fit: contain;
  width: 100%;
}
.copyright-text{
  margin-top:10px;
  padding-top:10px;
  border-top:1px solid #5a5a5a;
}
.top-footer p{
  text-align:center;
  margin:0;
  font-weight:400;
  font-size:14px;
  color:#a7a7a7;
}
.footer-title{
  font-size:18px;
  line-height: 18px;
  font-weight:500;
  color:#fff;
  text-transform:uppercase;
  position:relative;
  padding:0 0 0 15px;
  margin:0 0 15px 0;
  text-align:left;
  letter-spacing:.4px;
}
.footer-title:after{
  content:'';
  position:absolute;
  left:0;
  top: 50%;
  transform: translateY(-50%);
  width:4px;
  height:100%;
  background-color:#ea3708
}
.social-links{
  display:flex;
  align-items:center;
  justify-content:flex-start;
  margin:0 0 0px 0;
  padding:0
}
.call-us li,.social-links li{
  padding:0
}
.call-us li:first-child{
  margin-bottom:0px
}
.social-links li:not(:last-child){
  margin-right:25px
}
.call-us li a,.social-links li a{
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  -moz-transition:all .3s ease-in-out;
  -webkit-transition:all .3s ease-in-out;
  -ms-transition:all .3s ease-in-out;
  -o-transition:all .3s ease-in-out;
  transition:all .3s ease-in-out
}
.call-us li a i,.social-links li a i{
  font-size:22px;
  color:#fff;
  -moz-transition:all .3s ease-in-out;
  -webkit-transition:all .3s ease-in-out;
  -ms-transition:all .3s ease-in-out;
  -o-transition:all .3s ease-in-out;
  transition:all .3s ease-in-out
}
.footer-menu li a:hover,.social-links li a:hover i{
  color:#fcc900  !important
}
.call-us{
  margin:0;
  padding:0
}
.call-us li{
  width: auto;
  display: inline-block;
  margin-right: 25px
}
.call-us li:last-child{
  margin: 0;
}
.call-us li a{
  color:#fcc900  !important;
  font-size:16px;
  justify-content:flex-start
}
.call-us li a:hover, .call-us li a:hover i{
  color:#fff !important;
}
.call-us li a i{
  margin-right:7px;
  font-size:16px;
  color:#fcc900 ;
}
.footer-menu{
  display:flex;
  flex-direction:row;
  margin:0;
  padding:0;
  justify-content: center;
}
.footer-menu li{
  padding:0;
  width:auto;
  font-size:15px;
  line-height: 22px;
  text-align: left;
  margin: 0;
}
.footer-popular-events .footer-menu{
  flex-direction:row;
  flex-wrap:wrap;
  width:100%;
  justify-content: flex-end;
  align-items: center;
}
.footer-popular-events .footer-menu li{
  font-size: 24px;
  line-height: 24px;
  width: auto;
}
.footer-popular-events .footer-menu li.footer-facebook-icon i{
  font-size: 22px;
  line-height: 22px;
}
.footer-popular-events .footer-menu li:last-child a{
  height: 25px;
  display: flex;
}
.footer-popular-events .footer-menu li:last-child a img{
  height: 100%;
}
.footer-popular-events .footer-menu li:not(:last-child){
  margin: 0 20px 0 0;
}
.footer-menu li a{
  color:#fff !important;
}
.footer-support ul li a{
  margin-right: 10px;
}
.footer-support ul li a:last-child{
  margin: 0;
}
.footer-support ul li:after{
  display:inline-block;
  content: "|";
  padding: 0 10px;
  color: #fff;
}
.footer-support ul li:last-child:after{
  display: none;
}
.popular-event-category.footer-menu i {
  font-size: 26px;
}
@media (max-width: 1200px){
  .footer-menu {
      justify-content: flex-start;
  }
  .footer-support .footer-menu {
      flex-wrap: wrap;
  }
  .footer-contact-sec {
      width: 50%;
  }
  .footer-support {
      width: 100%;
      order: 3;
  }
  .footer-popular-events {
      width: 50%;
      order: 2;
  }
}
@media (max-width: 1024px){
  .footer-main-wrap {
    flex-wrap: wrap;
  }
  .footer-logo-sec{
    margin-bottom: 30px;
  }
  .footer-title {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .call-us {
    display: flex;
  }
  .call-us li {
    width: auto;
  }
  .call-us li:first-child {
    margin-bottom: 0;
    margin-right: 10px;
    position: relative;
    padding-right: 10px;
  }
  /* .call-us li:first-child:before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 100%;
    background-color: #fff;
  } */
  .footer-menu {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer-menu li {
    width: 33%;
  }
  .footer-popular-events .footer-menu li {
    width: auto;
    font-size: 22px;
    line-height: 22px;
  }
  .footer-popular-events .footer-menu li:not(:last-child) {
    margin-bottom: 0;
    margin-right: 15px;
  }
  .footer-support .footer-menu {
    width: 100%;
  }
  .footer-support .footer-menu li {
    width: auto;
    margin-right: 15px;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width:992px) and (max-width:1200px) {
  .top-footer p {
    font-size: 13px
  }
}
@media (max-width: 991px){
  .top-footer {
    text-align: center;
  }
  .call-us {
    flex-wrap: wrap;
  }
  .call-us li:first-child {
    margin-bottom: 10px;
  }
  .call-us li:first-child:before {
    display: none;
  }
  .footer-support .footer-menu li{
    margin-bottom: 5px;
  }
  .top-footer p{
    text-align:center
  }
  .footer-title:after{
    height: calc(100% - 4px);
  }
}
@media (max-width: 767px){
  .footer-logo-sec {
    width: 100%;
    margin-bottom: 25px;
  }
  .footer-contact-sec {
    width: 70%;
    margin-bottom: 10px;
  }
  .call-us li:first-child{
    margin-bottom: 0px;
  }
  .call-us li:first-child:before{
    display: inline-block;
  }
  .call-us li a {
    font-size: 14px;
  }
  .call-us li a i {
    font-size: 16px;
  }
  .footer-menu li {
    font-size: 13px;
    line-height: 18px;
  }
  .footer-popular-events .footer-menu li {
    width: auto;
    font-size: 20px;
    line-height: 20px;
  }
  .footer-support .footer-menu li{
    margin-right: 0px;
  }
  .top-footer p{
    margin:5px 0 0;
    font-size:13px
  }
  .footer-popular-events{
    margin-bottom: 10px;
  }
  .footer-popular-events{
    width: 30%;
  }
  .copyright-text{
    margin-top: 10px;
    padding-top: 10px;
  }
  .footer-support .footer-menu li{
    margin-bottom: 0px;
  }
  .top-footer .container{
    padding: 0 20px;
  }
}
@media (max-width: 575px){
  .footer-title {
    font-size: 14px;
    padding: 0 0 0 10px;
  }
  .footer-title:after {
    width: 3px;
    height: calc(100% - 6px);
  }
  .call-us li a i, .social-links li a i {
    font-size: 18px;
  }
  .call-us {
    padding: 0 0 0 10px;
  }
  .call-us li a {
    font-size: 14px;
  }
  .footer-menu li {
    font-size: 13px;
  }
  .top-footer p{
    margin:0 0 0;
    font-size:12px
  }
  .footer-contact-sec {
      width: 100%;
  }
  .footer-popular-events {
      width: 100%;
      order: 3;
      margin: 0;
  }
  .footer-support {
      width: 100%;
      order: 2;
  }
  .call-us {
      justify-content: center;
  }
  .popular-event-category.footer-menu {
      justify-content: center !important;
      margin-top: 10px;
  }
  .footer-contact-sec {
      margin: 0;
  }
  .footer-logo-sec {
      margin-bottom: 20px;
  }
  .top-footer .footer-logo-sec a img {
      max-height: 35px;
  }
  .footer-support ul li:after {
      padding: 0 5px;
  }
  .top-footer .container{
      padding: 0 10px;
  }
}
@media (max-width: 400px){
  .call-us li:first-child {
    padding-right: 10px;
    margin-right: 5px;
  }
  .call-us li:first-child:before {
    right: 2px;
  }
  .call-us li a {
    font-size: 13px;
  }
  .call-us li a i {
    font-size: 14px;
    margin-right: 5px;
  }
  .footer-popular-events .footer-menu li{
    font-size: 18px;
    line-height: 18px;
  }
}
</style>
<template>
  <footer id="colophon" class="site-footer">
    <div class="top-footer">
      <div class="container">
        <div class="footer-main-wrap">
          <div class="footer-logo-sec">
            <router-link to="/">
              <v-lazy-image :src="assetsUrl + 'images/logo-white.png'" width="50" height="200" alt="logo"></v-lazy-image>
            </router-link>
          </div>
          <div class="footer-contact-sec">
            <div class="call-us-section">
              <!-- <h3 class="footer-title">Contact us</h3> -->
              <ul class="call-us">
                <li><a :href="`tel:${phoneNumberTollFree}`"><i class="fa fa-phone" aria-hidden="true"></i>{{ phoneNumberTollFree }}</a></li>
                <li><a :href="`mailto:${contactEmail}`"><i class="fa fa-envelope" aria-hidden="true"></i>{{ contactEmail }}</a></li>
              </ul>
            </div>
          </div>
          <div class="footer-support">
            <!-- <h3 class="footer-title">Support</h3> -->
            <ul class="popular-event-category footer-menu">
              <li><router-link :to="{path: '/about-us'}">About Us</router-link></li>
              <li><router-link :to="{path: '/contact-us'}">Contact Us</router-link></li>
              <li><router-link :to="{path: '/terms-of-use'}">Terms and Conditions</router-link></li>
              <li><router-link :to="{path: '/privacy-policy'}">Privacy Policy</router-link></li>
            </ul>
          </div>
          <div class="footer-popular-events">
            <!-- <h3 class="footer-title">Connect</h3> -->
            <ul class="popular-event-category footer-menu">
              <li>
                <a target="_blank" href="https://www.instagram.com/litseats/">
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
              <li class="footer-facebook-icon">
                <a target="_blank" href="https://www.facebook.com/Litseats-108063031748417/">
                  <i class="fa fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <img :src="assetsUrl + 'images/shopper-approved-img.svg'" alt="shopper-approved-img" width="82" height="25">
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="copyright-text">
          <p>&copy; {{ new Date().getFullYear() }} {{ appName }}. All Right Reseved</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
/* eslint-disable */
// import $ from 'jquery'
import VLazyImage from 'v-lazy-image'

export default {
  name: 'Footer',
  components: {
    VLazyImage,
  },
  data () {
    return {
      appName: process.env.VUE_APP_NAME,
      assetsUrl: process.env.VUE_APP_ASSETS_URL,
      phoneNumberTollFree: process.env.VUE_APP_CONTACT_US_TOLL_FREE_NUMBER,
      phoneNumberInternational: process.env.VUE_APP_CONTACT_US_INTERNATIONAL_NUMBER,
      contactEmail: process.env.VUE_APP_CONTACT_US_EMAIL,
    }
  },
}
</script>
