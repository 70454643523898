<style scoped>
.site-header .top-header-bg{
  background-color:#1f1f1f;
  position: relative;
  display: flex;
}
.site-header{
  -webkit-transition: all 0.5s ease;
  -moz-transition: position 10s;
  -ms-transition: position 10s;
  -o-transition: position 10s;
  transition: all 0.5s ease;
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}
.top-header{
  padding:8px 0
}
.common-header .container {
  padding: 0 30px;
  width: 100%;
  max-width: 100%;
}
.top-header .top-left ul{
  margin:0;
  padding:0;
  list-style:none;
  display: flex;
  align-items: center;
}
.top-head-wrap{
  display: flex;
  align-items: center;
}
.top-header .top-left ul li {
  line-height: 18px;
}
.top-header .top-left ul li:not(:last-child){
  margin-right:20px!important
}
.top-header .top-left ul li a{
  color:#fff !important;
  font-size:12px;
  line-height: 18px;
}
.top-header .top-left ul li a i{
  color: #fff;
  margin:0 6px 0 0;
}
.top-header .top-left ul li a:hover{
  color:#f08100 !important;
}
.top-header .top-right{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-header .top-right a{
  display: inline-block;
}
.top-header .top-right a img{
  width: 100px;
}
.locationFilter:before{
  display: none;
  content: 'Current Location';
  text-align: center;
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  z-index: 1;
  font-size: 13px;
  padding: 5px;
  min-width: 120px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.locationFilter:after{
  display: none;
  content: '';
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #000;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.main-header{
  padding:5px 0;
  position:relative;
  /*z-index: 9;*/
}
.common-header .innerpage-header .main-header{
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}
.common-header.inner-header .innerpage-header .main-header{
  border-bottom:1px solid #ccc
}
.innerpage-header .main-header{
  -webkit-box-shadow:0 5px 5px 0 rgb(0 0 0 / 46%);
  -moz-box-shadow:0 5px 5px 0 rgba(0,0,0,.46);
  box-shadow:0 5px 5px 0 rgb(0 0 0 / 46%);
  background-color:#fff
}
.common-header .innerpage-header .header-wrapper{
  display:flex;
  align-items:center;
  justify-content:space-between;
  width: 100%;
}
.common-header .innerpage-header .header-wrapper .site-branding{
  width:200px;
  height:65px;
  /* margin-right:60px */
}
.common-header .innerpage-header .header-wrapper .site-branding .site-title{
  width:100%;
  height:100%;
  margin: 0px;
}
.common-header .innerpage-header .header-wrapper .search-wrapper .mobile-search-icon{
  display:none
}
.search-wrapper .header-search{
  position:relative
}
.search-wrapper .header-search .search-btn{
  position:absolute;
  left:15px;
  top:13px;
  border:0;
  padding:0;
  color:#fff;
  line-height:14px;
  width:17px;
  height:17px;
  background-image:url('~@/../public/images/header-search-icon.webp');
  background-repeat:no-repeat;
  background-position:center;
  background-size:17px;
  background-color: transparent;
}
.common-header .innerpage-header .header-wrapper .main-menu{
  margin-left:auto;
  display: flex;
}
.main-header .site-title a{
  display:flex;
  width:100%;
  height:100%;
  margin-top:0;
  transition:all .3s ease-in-out;
  -webkit-transition:all .3s ease-in-out;
  -o-transition:all .3s ease-in-out;
  -ms-transition:all .3s ease-in-out;
  -moz-transition:all .3s ease-in-out
}
.main-header .site-title a img{
  object-fit: contain;
  height: 100%;
}
.main-header #site-navigation{
  position: relative;
  border:0;
  border-radius:0;
  min-height:auto;
  margin: 0;
  padding: 0px;
}
.main-header #site-navigation .nav{
  align-items: center;
}
.navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
.navbar-toggler .icon-bar+.icon-bar {
  margin-top: 4px;
}
.closecanvas {
  display: none;
}
.main-header #site-navigation .nav.navbar-nav{
  display: flex;
  align-items: center;
}
.menu-head {
  display: none;
}
.main-header #site-navigation .nav .header-menu-container {
  float: left;
}
.main-header #site-navigation .nav .header-menu-container:not(:last-child){
  margin-right:10px
}
.main-header #site-navigation .nav .header-menu-container li .btn, .main-header #site-navigation .nav .header-menu-container li.desktop-menu-wrap a{
  display: flex;
  background-color: transparent;
  color: #04092c !important;
  border: 0;
  padding: 6px 12px;
  border-radius: 3px;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Heebo', sans-serif;
}
.main-header #site-navigation .nav .header-menu-container li .btn:after{
  display: none;
}
.main-header #site-navigation .nav .header-menu-container li .btn:hover,.main-header #site-navigation .nav .header-menu-container li.desktop-menu-wrap a:hover{
  color:#ea3708 !important;
  background-color:rgb(234 55 8/20%) !important;
}
.main-header #site-navigation .nav .header-menu-container li .btn:focus,.main-header #site-navigation .nav .header-menu-container li.desktop-menu-wrap a:focus{
  outline: none;
  box-shadow: none;
}
.main-header #site-navigation .nav .header-menu-container li.header-home-page-link{
  display: none;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu-open:hover + .dropdown-menu,
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu-open + .dropdown-menu:hover{
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.header-menu-content-wrapper{
  display: flex;
  width: 100%;
  -webkit-box-shadow: 0 10px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 10px 12px rgb(0 0 0 / 18%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 400px;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu{
  min-width:730px;
  border:0;
  border-radius:0;
  padding:20px 0 0px;
  border-radius:0;
  margin:0;
  background-color:transparent;
  box-shadow:none;
  left: auto;
  right: 0;
  top: 100%;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group.region-menu .dropdown-menu{
  max-height: 450px;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group.region-menu .dropdown-menu .dropdown-menu-list{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-box-shadow: 0 10px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 10px 12px rgb(0 0 0 / 18%);
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu.show{
  display: none;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list {
  max-height: 400px;
  overflow-y: auto;
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 15px 0;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list a,.main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu ul li a{
  color:#373737 !important;
  display:inline-block;
  vertical-align: middle;
  width: 30%;
  font-size:14px;
  line-height: 18px;
  font-family: 'Heebo', sans-serif;
  font-weight:500;
  margin: 0 7px;
  padding:8px;
  border-radius:3px;
  text-transform:capitalize;
  white-space: normal;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list a:hover,.main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu ul li a:hover{
  color:#ea3708 !important;
  background-color:rgb(234 55 8/20%);
  font-weight:600;
}
.mobile-menu,.mobile-location-wrapper{
  display: none;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav{
  padding: 12px 6px;
  border-radius: 0;
  max-height: initial;
  height: auto;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  width: 130px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom-left-radius: 10px;
  background-color: #f6f7fb;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group.alt-menu .dropdown-menu ul.nav{
  width: 160px;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group.alt-menu .dropdown-menu .tab-content{
  width: calc(100% - 160px);
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item{
  width: 100%;
  margin: 0px;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item .nav-link{
  border: 0;
  background-color: transparent;
  color: #373737;
  font-weight: 500;
  border-radius: 3px;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  width: 100%;
  padding: 6px 12px;
  font-size: 15px;
  line-height: 20px;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item .nav-link.active,
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item .nav-link:hover{
  color: #ea3708 !important;
  background-color: rgb(234 55 8/20%);
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item .nav-link.active a,
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item .nav-link:hover a{
  color: #ea3708 !important;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content{
  background-color: #fff;
  width: calc(100% - 130px);
  border-bottom-right-radius: 10px;
}
.main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .tab-pane{
  width: 100%;
}
.sticky-header .innerpage-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    animation: sticky 1.2s;
}
@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@media (min-width:768px){
  .common-header .container{
    max-width:100%;
  }
  .navbar-toggler {
    display: none;
  }
}
@media (min-width:992px){
  .main-menu .navbar-expand-lg .offcanvas{
    width: auto;
  }
}
@media (min-width:1024px){
  .common-header .container{
    padding:0 40px;
    width:100%;
    max-width:100%;
  }
}
@media screen and (max-width:1600px){
  .header-menu-content-wrapper{
    height: 350px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list{
    max-height: 350px;
  }
}
@media screen and (max-width: 1400px){
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu{
    min-width: 600px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group.alt-menu .dropdown-menu ul.nav{
    width: 140px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group.alt-menu .dropdown-menu .tab-content{
    width: calc(100% - 140px);
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav{
    width: 110px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list a, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu ul li a{
    width: 47%;
    margin: 0 5px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content{
    width: calc(100% - 110px);
  }
  .top-header{
    padding: 6px 0;
  }
  .top-header .top-right a img{
    width: 85px;
  }
}
@media screen and (max-width: 1200px){
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list{
    max-height: 320px;
  }
  .common-header .innerpage-header .header-wrapper .site-branding {
    margin-right: 30px;
  }
  .locationFilter:before{
    left: auto;
    transform: none;
    right: 0;
  }
  .locationFilter:after{
    left: auto;
    transform: none;
    right: 15px;
  }
  .main-header #site-navigation .nav .header-menu-container:not(:last-child){
    margin-right:5px!important;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav li{
    font-size: 14px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item .nav-link{
    padding: 4px 10px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list a, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu ul li a{
    font-size: 13px;
    line-height: 17px;
  }
  .header-menu-content-wrapper{
    height: 320px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .tab-pane ul{
    max-height: 320px;
  }
}
@media screen and (max-width: 991px){
  .mobile-location-wrapper{
    position: relative;
  }
  .navbar-toggler,.mobile-location-wrapper{
    display: block;
  }
  .main-header #site-navigation .navbar-toggler{
    background: 0 0;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    -webkit-border-radius:0px;
  }
  .main-header #site-navigation .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
  }
  .main-header #site-navigation .navbar-toggler .icon-bar {
    background: #f08000;
    transform: none!important;
    opacity: 1!important;
  }
  .main-header #site-navigation .offcanvas{
    width: 350px;
    border-right: 0px;
    box-shadow: 0 0 20px rgb(0 0 0 / 30%);
  }
  .main-header #site-navigation .menu-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 15px;
    background-color: rgb(244 156 0/20%);
  }
  .main-header #site-navigation .menu-head a{
    display:flex;
    width:60px;
  }
  .main-header #site-navigation .menu-head a img{
    width:100%;
  }
  .closecanvas {
    display: block!important;
    background: 0 0;
    border: none;
    color: #000;
    padding: 0;
    opacity: 1;
    width: auto;
    height: auto;
  }
  .closecanvas i{
    font-size: 24px;
  }
  .main-header #site-navigation .nav{
    display:flex!important;
    align-items:flex-end!important;
    flex-direction:column!important;
    margin-top:0!important;
    height:calc(100vh - 72px);
    overflow-y:scroll;
    padding:15px 10px;
    flex-wrap: nowrap;
  }
  .main-header #site-navigation .nav .header-menu-container:not(:last-child){
    margin-right:0!important;
  }
  .main-header #site-navigation .nav .header-menu-container{
    width:100%;
    float:none;
  }
  .main-header #site-navigation .nav li.desktop-menu-wrap{
    margin-right:0!important;
    width:100%;
    display:flex;
    align-items:center;
  }
  .main-header #site-navigation .nav .header-menu-container li.desktop-home{
    display: none;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group {
    width: 100%
  }
  .common-header .innerpage-header .main-header {
    height: 75px;
  }
  .common-header .innerpage-header .main-header .container{
    height: 100%;
  }
  .common-header .innerpage-header .header-wrapper{
    position: relative;
    height: 100%;
  }
  .common-header .innerpage-header .header-wrapper .site-branding {
    order: 2;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 60px;
    margin-right: 0;
  }
  .main-header .site-title a{
    width: auto;
    justify-content: center;
  }
  .common-header .innerpage-header .header-wrapper .search-wrapper{
    display: block;
    order: 3;
  }
  .common-header .innerpage-header .header-wrapper .search-wrapper .mobile-search-icon{
    display: flex;
    width: 18px;
    height: 18px;
  }
  .common-header .innerpage-header .header-wrapper .search-wrapper .mobile-search-icon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .common-header .search-wrapper .header-search{
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s ease-in-out;
    -moz-transition: opacity .4s ease-in-out;
    -webkit-transition: opacity .4s ease-in-out;
    -ms-transition: opacity .4s ease-in-out;
    -o-transition: opacity .4s ease-in-out;
  }
  .common-header .innerpage-header .header-wrapper .search-wrapper.mobile-search .hero-search.header-search{
    display:flex;
    align-items:center;
    position:fixed;
    left:50%;
    transform:translateX(-50%);
    top:20px;
    width:calc(100% - 20px);
    z-index:9999;
    opacity:1;
    visibility:visible;
  }
  .search-wrapper .header-search .search-btn {
    left: 20px;
    right: auto;
    top: 22px;
    width: 18px;
    height: 18px;
    background-size: 18px;
  }
  .common-header .innerpage-header .header-wrapper .main-menu{
    order: 1;
    margin-left: 0;
  }
  .common-header .innerpage-header .header-wrapper .search-wrapper.mobile-search:before{
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 100px;
    z-index: 9999;
  }
  .main-header #site-navigation .nav .header-menu-container li.desktop-menu-wrap a{
    width: 100%;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn,.main-header #site-navigation .nav .header-menu-container li.desktop-menu-wrap a{
    padding:10px 12px;
  }
  .main-header #site-navigation .nav .header-menu-container li.header-home-page-link {
    display: block;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group{
    display: none;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group.mobile-menu {
    display: block;
    position: relative;
    margin-top: 0;
  }
  .main-header:hover #site-navigation .nav .header-menu-container li .btn:hover{
    background-color: transparent !important;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn{
    color: #04092c;
    width: 100%;
    text-align: left;
    line-height: normal;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group.mobile-menu .btn{
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  .main-header #site-navigation .nav .header-menu-container li .mobile-menu:hover:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 44px;
    background-color: rgb(234 55 8/20%);
    border-radius: 6px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu {
    min-width: auto;
    margin: 15px 0;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu {
    margin-top: 10px!important;
    margin-bottom: 0!important;
    padding-top: 0;
    box-shadow: 0 0 12px rgb(0 0 0 / 18%);
    border-radius: 10px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu ul {
    border-top: 0;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 12px rgb(0 0 0 / 18%);
    box-shadow: 0 0 12px rgb(0 0 0 / 18%);
    padding: 10px 0;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list {
    height: calc(100vh - 330px);
    max-height: initial;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group.mobile-region-menu .dropdown-menu .dropdown-menu-list{
    border-top: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .dropdown-menu-list{
    height: calc(100vh - 365px);
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list a, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu ul li a{
    width: 48%;
    display: flex;
    margin: 0 3px;
    text-align: left;
  }
  .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group.mobile-menu{
    display:block;
    position:relative;
    margin-top:0;
    width:100%
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group.mobile-menu span,.main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group.mobile-menu span{
    width:20px;
    height:20px;
    display:flex;
    position:absolute;
    right:5px;
    top: 50%;
    transform: translateY(-50%);
    padding:0;
    border-radius:0;
    /*z-index:1*/
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group.mobile-menu span.nav-icon img{
    width:100%
  }
  .offcanvas .dropdown-menu{
    position: relative;
    width: 100%;
    border: inherit;
    box-shadow: none;
    transition: height .15s ease-in;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group.event-tabs .dropdown-menu{
    box-shadow: 0 0 12px rgb(0 0 0 / 18%);
    border-radius: 10px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav{
    height: auto;
    flex-direction: row !important;
    border-radius: 8px;
    margin: 0 !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: none;
    border-top: 0px;
    border-bottom: 1px solid #ccc;
    padding: 12px 15px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .dropdown-menu-list{
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
    box-shadow: none;
    border-top: 0px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav,
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content{
    width: 100%;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav{
    border-right: 0px;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item{
    width: auto;
    padding: 0px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content{
    border-bottom-left-radius: 10px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item .nav-link{
    padding: 6px 12px;
    min-width: auto;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .tab-pane ul{
    height: calc(100vh - 390px);
    max-height: initial;
    align-items: flex-start;
  }
  .header-menu-content-wrapper{
    flex-direction: column;
    box-shadow: 0 0px 12px rgb(0 0 0 / 18%);
    border-radius: 10px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav li{
    font-size: 13px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu ul.nav .nav-item a{
    min-width: 110px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu.alt-mobile-menu ul.nav{
    white-space: pre;
  }
}
@media screen and (max-width: 768px){
  .common-header .innerpage-header .header-wrapper .site-branding{
    height: 55px;
  }
}
@media screen and (max-width: 767px){
  .common-header .container{
    padding: 0 20px;
  }
  .inner-header .innerpage-header{
    position: relative
  }
  .top-header .top-left ul li:first-child{
    margin-right: 10px !important;
    padding-right: 10px;
    position: relative;
  }
  .top-header .top-left ul li:first-child:before{
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 100%;
    background-color: #fff;
  }
  .top-header .top-left ul li a{
    display: flex;
    align-items: center;
  }
  .top-header .top-left ul li a i{
    font-size: 16px;
    margin: 0px;
  }
  .top-header .top-left ul li a span{
    display: none;
  }
  .locationFilter:before{
    font-size: 12px;
    min-width: 105px;
    bottom: -41px;
  }
}
@media screen and (max-width: 575px){
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .dropdown-menu-list{
    height: calc(100vh - 390px);
  }
}
@media screen and (max-width: 480px){
  .common-header .container{
    padding:0 10px;
  }
  .top-header .top-left ul li{
    float: none;
    display: inline-block;
  }
  .search-wrapper .header-search .search-btn{
    left: 10px;
    width: 15px;
    height: 15px;
    background-size: 15px;
    top: 16px;
  }
  .common-header .innerpage-header .header-wrapper .search-wrapper.mobile-search .hero-search.header-search{
    top: 28px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn, .main-header #site-navigation .nav .header-menu-container li.desktop-menu-wrap a{
    padding: 8px 12px;
    font-size: 14px;
    line-height: 22px;
  }
  .main-header #site-navigation .nav .header-menu-container li .mobile-menu:hover:before{
    height: 37px;
  }
  .main-header #site-navigation .offcanvas{
    width: 100%;
  }
  .top-header .top-left ul li a i{
    font-size: 14px;
  }
  .top-header .top-right a img{
    width: 70px;
  }
}
@media screen and (max-width: 400px){
  .common-header .innerpage-header .main-header{
    height: 65px;
  }
  .top-header .top-left ul {
    flex-wrap: wrap;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list a, .main-header #site-navigation .nav.navbar-nav li.desktop-menu-wrap .btn-group .dropdown-menu ul li a{
    width: 100%;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .tab-pane ul{
    height: calc(100vh - 360px);
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list{
    height: calc(100vh - 330px);
  }
}
@media (min-width: 320px) and (max-width: 896px) and (orientation: landscape){
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .dropdown-menu-list {
    height: 450px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .dropdown-menu-list{
    height: 400px;
  }
  .main-header #site-navigation .nav .header-menu-container li .btn-group .dropdown-menu .tab-content .tab-pane ul{
    height: 450px;
  }
}
</style>
<template>
  <header id="masthead" ref="headRef" :class="`${headerClass}`">
    <div :class="`${topHeaderClass}`">
      <div class="container">
        <div class="top-head-wrap">
          <div class="top-left">
            <ul>
              <li>
                <a :href="`tel:${phoneNumberTollFree}`">
                  <i class="fa fa-phone" title="phone icon"></i>
                  <span>{{ phoneNumberTollFree }}</span>
                </a>
              </li>
              <li>
                <a :href="`mailto:${contactEmail}`">
                  <i class="fa fa-envelope-o" title="envelope icon"></i>
                  <span>{{ contactEmail }}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="top-right">
              <a href="javascript:void(0);">
                <v-lazy-image class="shop-approve-img" :src="assetsUrl + 'images/shopper-approved-img.svg'" alt="shopper-approved-img" width="100" height="31"></v-lazy-image>
              </a>
          </div>
        </div>
      </div>
    </div>
    <div class="innerpage-header">
      <div :class="`${mainHeaderClass}`">
          <div class="container">
            <div class="header-wrapper">
              <div class="site-branding">
                <h1 class="site-title">
                  <router-link to="/">
                    <v-lazy-image class="logo" :src="assetsUrl + 'images/logo.png'" height="100" width="500" :alt="appName"></v-lazy-image>
                  </router-link>
                </h1>
              </div>
              <!-- <div class="search-wrapper mobile-search-icon-j">
                <span class="mobile-search-icon"><v-lazy-image :src="assetsUrl + 'images/header-search-icon.webp'" alt="search-icon"></v-lazy-image></span>
                <div class="hero-search header-search">
                  <GlobalSearchAutocomplete/>
                  <button v-on:click="searchEvents" class="search-btn" type="submit"></button>
                </div>
              </div> -->
              <div class="menu-open-backdrop"></div>
              <div class="main-menu">
                <nav id="site-navigation" class="navbar navbar-expand-lg">
                  <button class="offcanvas-menu-toggle navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas" aria-controls="navbarOffcanvas" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                  <div class="offcanvas offcanvas-start" id="navbarOffcanvas">
                    <div class="menu-head">
                      <router-link to="/">
                        <v-lazy-image class="logo" :src="assetsUrl + 'images/logo.svg'" :alt="appName" width="60" height="55"></v-lazy-image>
                      </router-link>
                      <button type="button" class="offcanvas-menu-toggle btn-close closecanvas close-mobile-menu" data-bs-dismiss="offcanvas" data-target="#navbarOffcanvas">
                         <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                    <ul class="nav">
                      <!-- <span class="header-menu-container mobile-location-wrapper">
                        <li class="mobile-location-dropdown">
                          <LocationDropdown/>
                        </li>
                      </span> -->
                      <span class="header-menu-container">
                        <li class="desktop-menu-wrap desktop-home">
                          <router-link :to="{path: '/'}">Home</router-link>
                        </li>
                        <li class="desktop-menu-wrap header-home-page-link">
                          <router-link :to="{path: '/'}">Home</router-link>
                        </li>
                      </span>
                      <span class="header-menu-container" v-for="(item, index) in headerMenuItems" :key="index">
                        <li v-if="index != 'undefined'">
                          <div class="btn-group alt-menu"  v-if="index != 'SPORT' && index != 'REGION'">
                            <button v-if="index != 'THEATER'" v-on:click="redirectToCategoriesPage" class="btn btn-secondary dropdown-menu-open" type="button" :id="'dropdown-left '+ index" data-bs-toggle="dropdown" aria-expanded="false" :aria-labelledby="index" :data-event-type-name="index.toLowerCase()" :data-event-type="index" v-on:mouseover="hideHeaderDropdownFnc">{{ index + 'S' }}</button>
                            <button v-else v-on:click="redirectToCategoriesPage" class="btn btn-secondary dropdown-menu-open" type="button" :id="'dropdown-left '+ index" data-bs-toggle="dropdown" aria-expanded="false" :aria-labelledby="index" :data-event-type="index" :data-event-type-name="index.toLowerCase()" v-on:mouseover="hideHeaderDropdownFnc">{{ index }}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdown-left" role="menu" v-bind:style="{ display: hideHeaderDropdown }">
                              <div class="header-menu-content-wrapper" :id="`${index}-wrapper`">
                                <ul class="nav" :id="`pills-tab-${index}`" role="tablist">
                                  <li class="nav-item" role="presentation" v-for="(category, i) in item" :key="i">
                                      <router-link :to="`/performers/${category.categoryName.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '-')}/${category._id}`">
                                        <button  v-if="i === 0" class="nav-link show active" :id="`pills-${category._id}-tab`" data-bs-toggle="pill" :data-bs-target="`#pills-${category._id}`" type="button" role="tab" :aria-controls="`pills-${category._id}`" aria-selected="true" @mouseover="mouseOver('pills-'+category._id)">
                                          {{category.categoryName}}
                                        </button >
                                        <button  v-else class="nav-link" :id="`pills-${category._id}-tab`" data-bs-toggle="pill" :data-bs-target="`#pills-${category._id}`" type="button" role="tab" :aria-controls="`pills-${category._id}`" aria-selected="true" @mouseover="mouseOver('pills-'+category._id)">
                                            {{category.categoryName}}
                                        </button >
                                      </router-link>
                                  </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                  <template v-for="(category, i) in item" :key="i">
                                    <div v-if="i === 0" class="tab-pane fade show active" :id="`pills-${category._id}`" role="tabpanel" :aria-labelledby="`pills-${category._id}-tab`">
                                      <div class="dropdown-menu-list">
                                        <router-link :to="`/events/${subCategory.slug}/${subCategory.performerId}`" v-for="(subCategory, i) in category['data']" :key="i">
                                          {{ subCategory.name }}
                                        </router-link>
                                      </div>
                                    </div>
                                    <div v-else class="tab-pane fade show" :id="`pills-${category._id}`" role="tabpanel" :aria-labelledby="`pills-${category._id}-tab`">
                                      <div class="dropdown-menu-list">
                                        <router-link :to="`/events/${subCategory.slug}/${subCategory.performerId}`" v-for="(subCategory, i) in category['data']" :key="i">
                                          {{ subCategory.name }}
                                        </router-link>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="btn-group region-menu"  v-else-if="index === 'REGION'">
                            <button class="btn btn-secondary dropdown-menu-open" type="button" :id="'dropdown-left '+ index" data-bs-toggle="dropdown" aria-expanded="false" :aria-labelledby="index" :data-event-type-name="index.toLowerCase()" :data-event-type="index" v-on:mouseover="hideHeaderDropdown = ''">{{ index + 'S' }}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdown-left" role="menu" v-bind:style="{ display: hideHeaderDropdown }">
                              <div class="dropdown-menu-list">
                                <router-link :to="{path: '/events/region/'+category.slug+'/'+category.regionId}"  v-for="(category, i) in item" :key="i">
                                  {{ category.name }}
                                </router-link>
                              </div>
                            </div>
                          </div>
                          <div class="btn-group sports-menu"  v-else>
                            <button v-on:click="redirectToCategoriesPage" class="btn btn-secondary dropdown-menu-open" type="button" :id="'dropdown-left '+ index" data-bs-toggle="dropdown" aria-expanded="false" :aria-labelledby="index" :data-event-type-name="index.toLowerCase()" :data-event-type="index" v-on:mouseover="hideHeaderDropdownFnc">{{ index + 'S'}}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdown-left" role="menu" v-bind:style="{ display: hideHeaderDropdown }">
                              <div class="header-menu-content-wrapper" :id="`${index}-wrapper`">
                                <ul class="nav" id="pills-tab" role="tablist">
                                  <li class="nav-item" role="presentation">
                                    <router-link :to="{path: '/performers/mlb-baseball/2' }">
                                      <button class="nav-link active" id="pills-MLB-tab" data-bs-toggle="pill" data-bs-target="#pills-MLB" type="button" role="tab" aria-controls="pills-MLB" aria-selected="true" @mouseover="mouseOver('pills-MLB')">MLB</button>
                                    </router-link>
                                  </li>
                                  <li class="nav-item" role="presentation">
                                    <router-link :to="{path: '/performers/nfl-football/1' }">
                                      <button class="nav-link" id="pills-NFL-tab" data-bs-toggle="pill" data-bs-target="#pills-NFL" type="button" role="tab" aria-controls="pills-NFL" aria-selected="false" @mouseover="mouseOver('pills-NFL')">NFL</button>
                                    </router-link>
                                  </li>
                                  <li class="nav-item" role="presentation">
                                    <router-link :to="{path: '/performers/nhl-hockey/4' }">
                                      <button class="nav-link" id="pills-NHL-tab" data-bs-toggle="pill" data-bs-target="#pills-NHL" type="button" role="tab" aria-controls="pills-NHL" aria-selected="false" @mouseover="mouseOver('pills-NHL')">NHL</button>
                                    </router-link>
                                  </li>
                                  <li class="nav-item" role="presentation">
                                    <router-link :to="{path: '/performers/nba-basketball/3' }">
                                      <button class="nav-link" id="pills-NBA-tab" data-bs-toggle="pill" data-bs-target="#pills-NBA" type="button" role="tab" aria-controls="pills-NBA" aria-selected="false" @mouseover="mouseOver('pills-NBA')">NBA</button>
                                    </router-link>
                                  </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                  <div class="tab-pane fade show active" id="pills-MLB" role="tabpanel" aria-labelledby="pills-MLB-tab">
                                    <div class="dropdown-menu-list">
                                      <router-link :to="`/events/${category.slug}/${category.id}`" v-for="(category, i) in item.MLB" :key="i">
                                        {{ category.name }}
                                      </router-link>
                                    </div>
                                  </div>
                                  <div class="tab-pane fade" id="pills-NFL" role="tabpanel" aria-labelledby="pills-NFL-tab">
                                    <div class="dropdown-menu-list">
                                      <router-link :to="`/events/${category.slug}/${category.id}`" v-for="(category, i) in item.NFL" :key="i">
                                        {{ category.name }}
                                      </router-link>
                                    </div>
                                  </div>
                                  <div class="tab-pane fade" id="pills-NHL" role="tabpanel" aria-labelledby="pills-NHL-tab">
                                    <div class="dropdown-menu-list">
                                      <router-link :to="`/events/${category.slug}/${category.id}`" v-for="(category, i) in item.NHL" :key="i">
                                        {{ category.name }}
                                      </router-link>
                                    </div>
                                  </div>
                                  <div class="tab-pane fade" id="pills-NBA" role="tabpanel" aria-labelledby="pills-NBA-tab">
                                    <div class="dropdown-menu-list">
                                      <router-link :to="`/events/${category.slug}/${category.id}`" v-for="(category, i) in item.NBA" :key="i">
                                        {{ category.name }}
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="btn-group mobile-menu"  v-if="index != 'SPORT' && index != 'REGION'">
                            <button v-if="index != 'THEATER'" class="btn btn-secondary toggle-mobile-menu-dd" :id="index" :aria-labelledby="index">{{ index + 'S' }}<span class="nav-icon"><v-lazy-image :src="assetsUrl + 'images/right-angle.webp'" width="20" height="20"></v-lazy-image></span></button>
                            <button v-else class="btn btn-secondary toggle-mobile-menu-dd" :id="index" :aria-labelledby="index">{{ index }}<span class="nav-icon"><v-lazy-image :src="assetsUrl + 'images/right-angle.webp'" width="20" height="20"></v-lazy-image></span></button>
                            <div class="dropdown-menu mobile-menu-dd alt-mobile-menu" aria-labelledby="dropdown-left" role="menu">
                                <ul class="nav nav-pills" id="mobile-pills-tab" role="tablist">
                                  <li class="nav-item" role="presentation" v-for="(category, i) in item" :key="i">
                                  <button v-if="i === 0" class="nav-link active" :id="`mobile-pills-${category._id}-tab`" data-bs-toggle="pill" :data-bs-target="`#mobile-pills-${category._id}`" type="button" role="tab" :aria-controls="`mobile-pills-${category._id}`" aria-selected="true">
                                      {{category.categoryName}}
                                    </button >
                                    <button v-else class="nav-link" :id="`mobile-pills-${category._id}-tab`" data-bs-toggle="pill" :data-bs-target="`#mobile-pills-${category._id}`" type="button" role="tab" :aria-controls="`mobile-pills-${category._id}`" aria-selected="true">
                                      {{category.categoryName}}
                                    </button >
                                  </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                  <template v-for="(category, i) in item" :key="i">
                                    <div v-if="i === 0" class="tab-pane fade show active" :id="`mobile-pills-${category._id}`" role="tabpanel" :aria-labelledby="`mobile-pills-${category._id}-tab`">
                                      <div class="dropdown-menu-list">
                                        <router-link :to="`/events/${subCategory.slug}/${subCategory.performerId}`" v-for="(subCategory, i) in category['data']" :key="i">
                                          {{ subCategory.name }}
                                        </router-link>
                                      </div>
                                    </div>
                                    <div v-else class="tab-pane fade" :id="`mobile-pills-${category._id}`" role="tabpanel" :aria-labelledby="`mobile-pills-${category._id}-tab`">
                                      <div class="dropdown-menu-list">
                                        <router-link :to="`/events/${subCategory.slug}/${subCategory.performerId}`" v-for="(subCategory, i) in category['data']" :key="i">
                                          {{ subCategory.name }}
                                        </router-link>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                            </div>
                          </div>
                          <div class="btn-group mobile-menu mobile-region-menu"  v-else-if="index === 'REGION'">
                            <button class="btn btn-secondary toggle-mobile-menu-dd" type="button" :id="'dropdown-left ' + index" data-bs-toggle="dropdown" aria-expanded="false" :aria-labelledby="index" :data-event-type-name="index.toLowerCase()" :data-event-type="index" v-on:mouseover="hideHeaderDropdown = ''">{{ index + 'S' }}<span class="nav-icon"><v-lazy-image :src="assetsUrl + 'images/right-angle.webp'" width="20" height="20"></v-lazy-image></span></button>
                            <div class="dropdown-menu mobile-menu-dd" aria-labelledby="dropdown-left" role="menu" v-bind:style="{ display: hideHeaderDropdown }">
                              <div class="dropdown-menu-list">
                                <router-link :to="{path: '/events/region/'+category.slug+'/'+category.regionId}" v-for="(category, i) in item" :key="i">
                                  {{ category.name }}
                                </router-link>
                              </div>
                            </div>
                          </div>
                          <div class="btn-group mobile-menu event-tabs"  v-else>
                            <button class="btn btn-secondary toggle-mobile-menu-dd" :id="index" :aria-labelledby="index">{{ index }}<span class="nav-icon"><v-lazy-image :src="assetsUrl + 'images/right-angle.webp'" width="20" height="20"></v-lazy-image></span></button>
                            <div class="dropdown-menu mobile-menu-dd" aria-labelledby="dropdown-left" role="menu">
                              <ul class="nav nav-pills" id="mobile-pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link active" id="mobile-pills-MLB-tab" data-bs-toggle="pill" data-bs-target="#mobile-pills-MLB" type="button" role="tab" aria-controls="mobile-pills-MLB" aria-selected="true">MLB</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="mobile-pills-NFL-tab" data-bs-toggle="pill" data-bs-target="#mobile-pills-NFL" type="button" role="tab" aria-controls="mobile-pills-NFL" aria-selected="false">NFL</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="mobile-pills-NHL-tab" data-bs-toggle="pill" data-bs-target="#mobile-pills-NHL" type="button" role="tab" aria-controls="mobile-pills-NHL" aria-selected="false">NHL</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="mobile-pills-NBA-tab" data-bs-toggle="pill" data-bs-target="#mobile-pills-NBA" type="button" role="tab" aria-controls="mobile-pills-NBA" aria-selected="false">NBA</button>
                                </li>
                              </ul>
                              <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="mobile-pills-MLB" role="tabpanel" aria-labelledby="mobile-pills-home-tab">
                                  <div class="dropdown-menu-list">
                                    <router-link :to="`/events/${category.slug}/${category.id}`" v-for="(category, i) in item.MLB" :key="i">
                                      {{ category.name }}
                                    </router-link>
                                  </div>
                                </div>
                                <div class="tab-pane fade" id="mobile-pills-NFL" role="tabpanel" aria-labelledby="mobile-pills-profile-tab">
                                  <div class="dropdown-menu-list">
                                    <router-link :to="`/events/${category.slug}/${category.id}`" v-for="(category, i) in item.NFL" :key="i">
                                      {{ category.name }}
                                    </router-link>
                                  </div>
                                </div>
                                <div class="tab-pane fade" id="mobile-pills-NHL" role="tabpanel" aria-labelledby="mobile-pills-contact-tab">
                                  <div class="dropdown-menu-list">
                                    <router-link :to="`/events/${category.slug}/${category.id}`" v-for="(category, i) in item.NHL" :key="i">
                                      {{ category.name }}
                                    </router-link>
                                  </div>
                                </div>
                                <div class="tab-pane fade" id="mobile-pills-NBA" role="tabpanel" aria-labelledby="mobile-pills-contact-tab">
                                  <div class="dropdown-menu-list">
                                    <router-link :to="`/events/${category.slug}/${category.id}`" v-for="(category, i) in item.NBA" :key="i">
                                      {{ category.name }}
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </span>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint-disable */
import $ from 'jquery'
import axios from 'axios'
import Cookies from 'js-cookie'
// import _ from 'lodash'
import GlobalSearchAutocomplete from '../../../views/autocomplete/GlobalSearchAutocomplete'
import LocationDropdown from './Location'
import VLazyImage from 'v-lazy-image'

export default {
  name: 'Header',
  components: {
    GlobalSearchAutocomplete,
    LocationDropdown,
    VLazyImage
  },
  data: function () {
    /* eslint-disable */
    return {
      headerClass: this.$route.meta.headerClass ? this.$route.meta.headerClass : 'site-header common-header',
      topHeaderClass: this.$route.meta.topHeaderClass ? this.$route.meta.topHeaderClass : 'top-header top-header-bg',
      mainHeaderClass: this.$route.meta.mainHeaderClass ? this.$route.meta.mainHeaderClass : 'main-header',
      hideHeaderDropdown: '',
      assetsUrl: process.env.VUE_APP_ASSETS_URL,
      appName: process.env.VUE_APP_NAME,
      phoneNumberTollFree: process.env.VUE_APP_CONTACT_US_TOLL_FREE_NUMBER,
      phoneNumberInternational: process.env.VUE_APP_CONTACT_US_INTERNATIONAL_NUMBER,
      contactEmail: process.env.VUE_APP_CONTACT_US_EMAIL,
      userLocationData: [],
      ipDataFetchAddress: 'https://api.ipify.org',
      headerMenuItems: [],
      headerMenuItemsSportsMLB: [],
      headerMenuItemsSportsNFL: [],
      headerMenuItemsSportsNHL: [],
      headerMenuItemsSportsNBA: [],
      headerMenuItemsConcerts: [],
      headerMenuItemsTheater: [],
      currentLocation: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')) : '',
      selectedLocation: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')) : '',
      locationFilter: ''
    }
    /* eslint-enable */
  },
  mounted () {
    this.vueOnScroll()
    setTimeout(function (scope) {
      this.currentLocation = Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')) : ''
      this.selectedLocation = Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')) : ''
      // console.log(this.selectedLocation)
      if (this.selectedLocation) {
        this.locationFilter = this.selectedLocation.text ? this.selectedLocation.text : ''
      } else if (this.currentLocation) {
        if (JSON.parse(Cookies.get('userLocation')).country && JSON.parse(Cookies.get('userLocation')).city) {
          this.locationFilter = this.currentLocation.city + ', ' + this.currentLocation.country
        }
      } else {
        this.locationFilter = ''
      }
      if (this.locationFilter) {
        // document.getElementsByClassName('locationFilter')[0].innerHTML = this.locationFilter
      }
    }, 2000, this)
    // axios.get(process.env.VUE_APP_API_URL + 'getCategories', {
    // }).then(response => {
    //   if (response.status === 200) {
    //     this.headerMenuItems = _.groupBy(response.data.data.categories, 'eventType')
    //   }
    // })
    this.getMenuCategories()
    /* eslint-disable */
    $("body").on("click", '.header-home-page-link', function (e) {
      $(this).closest('.main-menu').find('.offcanvas').removeClass('show')
    })
    $("body").on("click", '.dropdown-menu-list li a, .header-home-page-link', function (e) {
      $('.offcanvas-backdrop').remove()
      $('body').removeAttr("style")
      $('.offcanvas').css("visibility", "hidden").removeAttr("aria-modal role").attr("aria-hidden", "true")
    })
    $("body").on("click", '.toggle-mobile-menu-dd', function (e) {
      $(this).closest('.mobile-menu').find('.mobile-menu-dd').slideToggle('fast')
    })
    $("body").on("click", '.mobile-search-icon', function (e) {
      $('.mobile-search-icon-j').toggleClass('mobile-search')
      $('.mobile-search-cancel').show()
      $('body').addClass('global-search-blur')
    })
    $("body").on("click", '.mobile-search-cancel', function (e) {
      $('.mobile-search-icon-j').removeClass('mobile-search')
      $('body').removeClass('global-search-blur')
      $('#globalSearchInput').val('')
    })
    $("body").on("click", '.mobile-menu-item-dd, .tab-pane .dropdown-menu-list li a', function (e) {
      $(this).closest('.offcanvas').removeClass('show')
      $('.mobile-menu-dd').hide()
    })
    $("body").on('click', '.dropdown-menu a label', function(e) {
      var getValue = $(this).text();
      $('.dropdown .btn .selected-filter').text(getValue);
    });
    /* eslint-enable */
  },
  methods: {
    vueOnScroll () {
      const refs = this.$refs.headRef
      window.addEventListener('scroll', () => {
        var curr = window.pageYOffset
        if (curr >= 100) {
          refs.classList.add('sticky-header')
        }
        if (curr === 0) {
          refs.classList.remove('sticky-header')
        }
      })
    },
    mouseOver: function (val) {
      $('.tab-pane').removeClass('show active')
      $('#' + val).addClass('show active')
      $('.nav-link').removeClass('active')
      $('#' + val + '-tab').addClass('show active')
    },
    redirectToCategoriesPage: function (event) {
      this.hideHeaderDropdown = 'none'
      this.$router.push({
        path: '/category/' + event.target.getAttribute('data-event-type-name')
        // query: { type: event.target.getAttribute('data-event-type'), eventType: event.target.getAttribute('data-event-type') }
      })
    },
    hideHeaderDropdownFnc: function (event) {
      this.hideHeaderDropdown = ''
      var mainId = event.target.getAttribute('data-event-type')
      var mainDiv = document.getElementById(mainId + '-wrapper')
      if (mainId === 'SPORT') {
        for (var k = 0; k < mainDiv.childNodes[1].children.length; k++) {
          var pillZeroSport = mainDiv.childNodes[1].children[k]
          pillZeroSport.classList.remove('show')
          pillZeroSport.classList.remove('active')
        }
        for (var x = 0; x < mainDiv.childNodes[0].children.length; x++) {
          var pillOneSport = mainDiv.childNodes[0].children[x].children[0].children[0]
          pillOneSport.classList.remove('show')
          pillOneSport.classList.remove('active')
        }
        mainDiv.childNodes[1].firstChild.classList.add('show')
        mainDiv.childNodes[1].firstChild.classList.add('active')
        mainDiv.childNodes[0].firstChild.children[0].children[0].classList.add('show')
        mainDiv.childNodes[0].firstChild.children[0].children[0].classList.add('active')
      } else {
        for (var i = 0; i < mainDiv.childNodes[0].children.length; i++) {
          var pillZero = mainDiv.childNodes[0].children[i].firstChild.children[0]
          pillZero.classList.remove('show')
          pillZero.classList.remove('active')
        }
        for (var j = 0; j < mainDiv.childNodes[1].children.length; j++) {
          var pillOne = mainDiv.childNodes[1].children[j]
          pillOne.classList.remove('show')
          pillOne.classList.remove('active')
        }
        mainDiv.childNodes[1].firstChild.nextElementSibling.classList.add('show')
        mainDiv.childNodes[1].firstChild.nextElementSibling.classList.add('active')
        mainDiv.childNodes[0].firstChild.nextSibling.lastChild.children[0].classList.add('show')
        mainDiv.childNodes[0].firstChild.nextSibling.lastChild.children[0].classList.add('active')
      }
    },
    getMenuCategories: function () {
      axios.get(process.env.VUE_APP_API_URL + 'getMenuCategories', {
      }).then(response => {
        if (response.status === 200) {
          response.data.data.results.SPORT[0].data
            .sort((a, b) => a.name > b.name ? 1 : -1)
            .map(x => {
              this.headerMenuItemsSportsMLB.push({ id: x.performerId, name: x.name, slug: x.slug })
            })
          response.data.data.results.SPORT[1].data
            .sort((a, b) => a.name > b.name ? 1 : -1)
            .map(x => {
              this.headerMenuItemsSportsNBA.push({ id: x.performerId, name: x.name, slug: x.slug })
            })
          response.data.data.results.SPORT[2].data
            .sort((a, b) => a.name > b.name ? 1 : -1)
            .map(x => {
              this.headerMenuItemsSportsNFL.push({ id: x.performerId, name: x.name, slug: x.slug })
            })
          response.data.data.results.SPORT[3].data
            .sort((a, b) => a.name > b.name ? 1 : -1)
            .map(x => {
              this.headerMenuItemsSportsNHL.push({ id: x.performerId, name: x.name, slug: x.slug })
            })
          // response.data.data.results.THEATER
          //   .sort((a, b) => a.name > b.name ? 1 : -1)
          //   .map(x => {
          //     this.headerMenuItemsTheater.push({ id: x.performerId, name: x.name, slug: x.slug })
          //   })
          // response.data.data.results.CONCERT
          //   .sort((a, b) => a.name > b.name ? 1 : -1)
          //   .map(x => {
          //     this.headerMenuItemsConcerts.push({ id: x.performerId, name: x.name, slug: x.slug })
          //   })
          this.headerMenuItems = response.data.data.results
          this.headerMenuItems.CONCERT = response.data.data.results.CONCERT
          this.headerMenuItems.THEATER = response.data.data.results.THEATER
          this.headerMenuItems.SPORT = []
          this.headerMenuItems.SPORT.MLB = this.headerMenuItemsSportsMLB
          this.headerMenuItems.SPORT.NFL = this.headerMenuItemsSportsNFL
          this.headerMenuItems.SPORT.NHL = this.headerMenuItemsSportsNHL
          this.headerMenuItems.SPORT.NBA = this.headerMenuItemsSportsNBA
        }
      })
    },
    searchEvents: function () {
      window.location.href = process.env.BASE_URL + 'events?q=' + this.$refs.searchEventInput.value
    }
  }
}
</script>
