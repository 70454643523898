import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueGtag from 'vue-gtag-next'
// import VueNextSelect from 'vue-next-select'
import Cookies from 'js-cookie'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'font-awesome/css/font-awesome.css'
import VueConfetti from 'vue-confetti'

// import 'tiny-slider/dist/tiny-slider.css'

const app = createApp(App)

if (!Cookies.get('userLocation')) {
  /* axios.get('ipinfo.io?token=f5785c5d2d6d0c').then(response => {
    Cookies.set('userLocation', response.country)
    console.log(response.country)
  }) */
  fetch('https://ipinfo.io/json?token=f5785c5d2d6d0c').then(
    (response) => response.json()
  ).then(
    (jsonResponse) =>
    // console.log(jsonResponse)
      Cookies.set('userLocation', JSON.stringify(jsonResponse))
  )
} else {
  const location = JSON.parse(Cookies.get('userLocation'))
  if (!location.loc) {
    Cookies.set('userLocation', '')
    fetch('https://ipinfo.io/json?token=f5785c5d2d6d0c').then(
      (response) => response.json()
    ).then(
      (jsonResponse) =>
      // console.log(jsonResponse)
        Cookies.set('userLocation', JSON.stringify(jsonResponse))
    )
  }
}
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const userEmail = urlParams.get('email')
if (userEmail) {
  Cookies.set('userEmail', userEmail)
}

// navigator.geolocation.getCurrentPosition(setUserLocationData, geoError)
// function setUserLocationData (position) {
//   if (position && position.coords) {
//     console.log('Latitude: ' + position.coords.latitude +
//   '<br>Longitude: ' + position.coords.longitude)
//   }
// }
// function geoError () {
//   if (!Cookies.get('userLocationPostal')) {
//     fetch('https://ipinfo.io/json?token=f5785c5d2d6d0c').then(
//       (response) => response.json()
//     ).then(
//       (jsonResponse) => console.log('cookie')// Cookies.set('userLocation', jsonResponse.region)
//     )
//   }
// }

/* var watchId = navigator.geolocation.watchPosition(function (position) {
  document.getElementById('currentLat').innerHTML = position.coords.latitude;
  document.getElementById('currentLon').innerHTML = position.coords.longitude;
}); */

// app.component('vue-select', VueNextSelect).use(store).use(router).use(Cookies).use(VueGtag, {
//   property: {
//     // id: 'UA-213542721-1'
//     // id: 'G-5V20P7V44C'
//     id: 'GTM-THLDSVV'
//   }
// }).mount('#app')

app.use(store).use(router).use(Cookies).use(VueConfetti).mount('#app')
