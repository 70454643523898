<style scoped>
.location-dropdown{
  width: 100%;
}
.location-dropdown .location-name{
  background-color: transparent;
  border: 0;
  padding: 0px 35px 0px 0px;
  width: 100%;
  color: #fff;
  text-align: right;
  font-size: 14px;
}
.location-dropdown .location-name.dropdown-toggle:after{
  display: none;
}
.location-dropdown .dropdown-menu.show{
  z-index: 9999;
  min-width: 300px;
  left: auto !important;
  right: 0 !important;
  padding: 0;
  border-radius: 0;
  border: 0;
  background-color: transparent;
}
.location-dropdown .dropdown-menu.show ul{
  margin: 5px 0 0 0;
  padding: 16px 8px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 30%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 400px;
  overflow-y: auto;
}
.location-dropdown .dropdown-menu.show li{
  position: relative;
}
.location-dropdown .dropdown-menu.show li:not(:last-child){
  margin-bottom: 4px;
}
.location-dropdown .dropdown-menu.show li input{
  border: 0px;
  padding: 3px 28px 3px 8px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  border-radius: 0;
  -webkit-border-radius:0px;
}
.location-search-icon{
  position: absolute;
  right: 8px;
  top: 6px;
  line-height: 16px;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.location-icon{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.location-dropdown .dropdown-menu.show li .dropdown-item{
  padding: 5px 8px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;
  font-weight: 500;
}
.location-dropdown .dropdown-menu.show li .dropdown-item:hover{
  color: #ea3708 !important;
  background-color: rgb(234 55 8/20%);
}
.location-detect-icon:hover:before,
.location-detect-icon:hover:after{
  display: inline-block;
}
.location-detect-icon:before{
  display: none;
  content: 'Go to Current Location';
  text-align: center;
  position: absolute;
  bottom: -37px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  z-index: 1;
  font-size: 13px;
  padding: 5px;
  min-width: 155px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.location-detect-icon:after{
  display: none;
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #000;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
@media(max-width: 1440px){
  .location-detect-icon:before{
    left: auto;
    transform: none;
    right: 0;
  }
}
@media(max-width: 767px){
  .location-dropdown .location-name{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .location-dropdown .dropdown-menu.show ul{
    margin: 3px 0 0 0;
  }
  .location-detect-icon:before{
    font-size: 12px;
    bottom: -36px;
    min-width: 145px;
  }
}
@media(max-width: 480px){
  .location-icon{
    right: 0;
  }
  .location-dropdown .location-name{
    padding: 0px 18px 0px 0px;
    font-size: 13px;
  }
  .location-dropdown .dropdown-menu.show ul{
    margin: 4px 0 0 0;
    padding: 12px 8px;
  }
}
@media(max-width: 480px){
  .location-dropdown .dropdown-menu.show{
    min-width: 250px;
  }
  .location-dropdown .dropdown-menu.show li input{
    font-size: 14px;
  }
  .location-search-icon{
    top: 4px;
  }
  .location-search-icon i{
    font-size: 14px;
  }
}
</style>
<template>
  <div class="location-dropdown">
    <div class="location-wrapper">
      <span class="location-icon"><i class="fa fa-map-marker"></i></span>
      <Select2 ref="sortByLocationZipcode" name="sortByLocationZipcode" v-model="LocationZipcodeOption" :options="LocationZipcodeSortingOptions" :settings="{ placeholder: LocationZipcodeTextOption, minimumInputLength:2, ajax:ajaxOptions }" @select="searchLocationZipcode($event)" />
    </div>
    <div class="disabled-location-detect-icon common-location-btn" v-if="disableCurrentLocation">
      <button class="search-ticket-btn current-location-btn" aria-label="current-location-btn" name="current-location-btn" disabled><svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="4"/><path d="M13 4.069V2h-2v2.069A8.01 8.01 0 0 0 4.069 11H2v2h2.069A8.008 8.008 0 0 0 11 19.931V22h2v-2.069A8.007 8.007 0 0 0 19.931 13H22v-2h-2.069A8.008 8.008 0 0 0 13 4.069zM12 18c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6z"/></svg></button>
    </div>
    <div class="location-detect-icon common-location-btn" v-else>
      <button class="search-ticket-btn current-location-btn" aria-label="current-location-btn" name="current-location-btn" v-on:click="setDeafultLocation">
        <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="4"/><path d="M13 4.069V2h-2v2.069A8.01 8.01 0 0 0 4.069 11H2v2h2.069A8.008 8.008 0 0 0 11 19.931V22h2v-2.069A8.007 8.007 0 0 0 19.931 13H22v-2h-2.069A8.008 8.008 0 0 0 13 4.069zM12 18c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6z"/></svg>
      </button>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import axios from 'axios'
import Cookies from 'js-cookie'
import Select2 from 'vue3-select2-component'
export default {
  name: 'Location',
  components: {
    Select2
  },
  data: function () {
    /* eslint-disable */
    return {
      currentLocation: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')) : '',
      selectedLocation: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')) : '',
      disableCurrentLocation: Cookies.get('userSearchLocation') ? false : true,
      LocationZipcodeOption: Cookies.get('userLocation') ? [JSON.parse(Cookies.get('userLocation')).postal] : '',
      LocationZipcodeSortingOption: [],
      LocationZipcodeSortingOptions: [
        {
          id: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')).postal: '',
          text: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')).city + ', ' + JSON.parse(Cookies.get('userLocation')).country: ''
        },
        {
          id: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')).text.split(',').pop(): '',
          text: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')).text : ''
        }
      ],
      LocationZipcodeTextOption: 'City or Zip Code',
      ajaxOptions: {
        url: process.env.VUE_APP_API_URL + 'getLocationZipcode',
        data: function(params) {
          return {
            term: params.term, // search term
            page: params.page
          };
        },
        processResults: function(data, params) {
          params.page = params.page || 1;
          return {
            results: $.map(data.results, function(item) {
                return {
                    text: item.place + ', ' + item.state + ', ' + item.country + ', ' + item.postalCode,
                    id: item.longitude + ',' + item.latitude
                }
            }),
            pagination: {
              more: (params.page * 29) < params.page * data.results.length
            }
          };
        },
        cache: true
      }
    }
  },
  mounted () {
    setTimeout(function () {
        this.LocationZipcodeSortingOptions = [
        {
          id: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')).postal: '',
          text: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')).city + ', ' + JSON.parse(Cookies.get('userLocation')).country: ''
        },
        {
          id: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')).text.split(',').pop(): '',
          text: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')).text : ''
        }
      ]
      this.LocationZipcodeOption = Cookies.get('userLocation') ? [JSON.parse(Cookies.get('userLocation')).postal] : ''
      if(document.querySelector('.home-location-wrapper .select2-selection__rendered')){
        document.querySelector('.home-location-wrapper .select2-selection__rendered').innerHTML = ""
      document.querySelector('.home-location-wrapper .select2-selection__rendered').insertAdjacentHTML("afterbegin", Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')).text : JSON.parse(Cookies.get('userLocation')).city + ', ' + JSON.parse(Cookies.get('userLocation')).country)
      }
      // console.log(this.LocationZipcodeSortingOptions)
      // console.log(this.LocationZipcodeTextOption)
      // console.log(this.LocationZipcodeTextOption)
    }, 3000);
    if (this.selectedLocation){
      var text = this.selectedLocation.text
      this.LocationZipcodeOption =  [text.split(',').pop()]
    }
  },
  methods: {
    searchLocationZipcode({ id, text, selected }) {
      var latLong = id.split(',')
      var lang = latLong[0]
      var lat = latLong[1]
      latLong = {lang, lat, text}
      Cookies.set('userSearchLocation', JSON.stringify(latLong))
      this.$emit("customChange", 'userSearchLocation')
      this.$emit("customChangeEvents", 'userSearchLocation')
      this.disableCurrentLocation = false
    },
    setDeafultLocation(){
      Cookies.get('userSearchLocation') ? Cookies.remove('userSearchLocation') : ''
      this.$emit("customChange", 'userLocation')
      this.$emit("customChangeEvents", 'userLocation')
      this.disableCurrentLocation = true
      this.LocationZipcodeSortingOptions = [
        {
          id: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')).postal: '',
          text: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')).city + ', ' + JSON.parse(Cookies.get('userLocation')).country: ''
        }
      ];
      this.LocationZipcodeOption = Cookies.get('userLocation') ? [JSON.parse(Cookies.get('userLocation')).postal] : ''
    }
  }
}
</script>