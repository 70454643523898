<template>
  <Header />
    <router-view :key="$route.fullPath" />
  <Footer />
</template>

<script>
import Footer from './footer/Footer'
import Header from './header/Header'
export default {
  name: 'Main',
  components: {
    Header,
    Footer
  }
}
</script>
